import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {cambiarcontraseña} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
// import { TextLinkModal } from '../../../../_heomoney/partials/modals/textlink-modal/TextLinkModal'
import {useIntl} from 'react-intl'
import {sha256} from 'js-sha256'
import {cambiarcontraseña} from '../core/_requests'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  code: '',
}

export function RecoverPassword() {
  const [loading, setLoading] = useState(false)
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  })

  const intl = useIntl()
  const navigate = useNavigate()
  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILFORMAT'}))
      .min(3, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMIN'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMAX'}))
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILREQUIRED'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'VALIDATION.LENGTHD'}, {min: 8}))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        intl.formatMessage({id: 'VALIDATION.RULES'})
      )
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDREQUIRED'})),
    changepassword: Yup.string()
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDCREQUIRED'}))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({id: 'VALIDATION.PASSWORDC'})
        ),
      }),
    code: Yup.string()
      .min(3, intl.formatMessage({id: 'VALIDATION.INVALID'}))
      .max(50, intl.formatMessage({id: 'VALIDATION.INVALID'}))
      .required(intl.formatMessage({id: 'VALIDATION.REQUIRED'})),
  })

  const validatePassword = (value: string) => {
    setPasswordChecks({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;
      try {
        setLoading(true)
        console.log('empieza try')
        console.log(values)
        const {data: Register} = await cambiarcontraseña(
          values.email,
          sha256(process.env.REACT_APP_SALT + sha256(values.password)),
          values.code,
          dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual
        )
        console.log(Register)
        if (Register.message.msgId == '0') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${Register.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar sesión',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/auth/login')
            }
          })
          setLoading(false)

          // Mostrar mensaje de usuario autorizado
        } else {
          setSubmitting(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${Register.message.msgTxt}`,
            showConfirmButton: true,
          })

          setLoading(false)
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${error}`,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
        })
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'RECOVER.TITLE'})}</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'RECOVER.TEXT2'})}
        </div>
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}

      {/* end::Login options */}

      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>{intl.formatMessage({id: 'LOGIN.TEXT3'})}</span>
      </div> */}

      {/* {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )} */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'RECOVER.TEXT.LABELEMAIL'})}
        </label>
        {/* <input type="text"  value={EmailEnviado} /> */}

        <input
          placeholder={intl.formatMessage({id: 'RECOVER.TEXT.LABELEMAIL'})}
          type='email'
          // value={EmailEnviado}

          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />

        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'RECOVER.TEXT.LABELCONTRASEÑA'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'RECOVER.TEXT.LABELCONTRASEÑA'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              onChange={(e) => {
                formik.handleChange(e) // Sigue manejando el cambio en Formik
                validatePassword(e.target.value) // Llama a la función para validar la contraseña
              }}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            <ul className='mt-2'>
              <li className={passwordChecks.length ? 'text-success' : 'text-danger'}>
                {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDLENGTH'})}
              </li>
              <li className={passwordChecks.uppercase ? 'text-success' : 'text-danger'}>
                {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMAYUS'})}
              </li>
              <li className={passwordChecks.lowercase ? 'text-success' : 'text-danger'}>
                {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMINUS'})}
              </li>
              <li className={passwordChecks.number ? 'text-success' : 'text-danger'}>
                {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDNUM'})}
              </li>
            </ul>
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        {/* <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'RECOVER.TEXT.LABELCPASSWORD'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'RECOVER.TEXT.LABELCPASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group code */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'RECOVER.CODE'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'RECOVER.CODE'})}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.code && formik.errors.code,
            },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // onClick={() => setShowTextLinkModal(true)}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && 'Enviar'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Espere... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/forgot-password'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'TEXT.CANCEL'})}
          </button>
        </Link>
      </div>

      {/* end::Form group */}
    </form>
  )
}
