import {Controller, useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {addAddress, getLocalizaciones} from '../../core/_requests'
import {useNavigate} from 'react-router-dom'
import {LocalizacionesResponseModel} from '../../core/_models'
import {Dropdown, DropdownButton, Spinner} from 'react-bootstrap'
import {useEffect, useState} from 'react'
const StepNine = ({
  prevStep,
  nextStep,
  localizacionesNivel1,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  localizacionesNivel1: LocalizacionesResponseModel
  lastShowedStep: string
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const paisStepper = useStepperStore((state) => state.dataStepper.country)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const [loadingNivel2, setLoadingNivel2] = useState<boolean>(false)
  const [loadingNivel3, setLoadingNivel3] = useState<boolean>(false)
  const [idNivel2, setIdNivel2] = useState<number>(0)
  const [idNivel3, setIdNivel3] = useState<number>(0)
  const [idNivel4, setIdNivel4] = useState<number>(0)
  const [localizacionesNivel2, setLocalizacionesNivel2] = useState<LocalizacionesResponseModel>(
    {} as LocalizacionesResponseModel
  )

  const [localizacionesNivel3, setLocalizacionesNivel3] = useState<LocalizacionesResponseModel>(
    {} as LocalizacionesResponseModel
  )

  const {
    register,
    setValue,
    control,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm()

  const backStep = () => {
    prevStep()
  }

  const traerLocalizacionesNivel2 = async () => {
    // Traer paises
    setLoadingNivel2(true)
    const {data} = await getLocalizaciones(paisStepper ? paisStepper : 'PE', idNivel2)
    setLocalizacionesNivel2(data)
    setLoadingNivel2(false)
  }

  const traerLocalizacionesNivel3 = async () => {
    // Traer paises
    setLoadingNivel3(true)
    const {data} = await getLocalizaciones(paisStepper ? paisStepper : 'PE', idNivel3)
    setLocalizacionesNivel3(data)
    setLoadingNivel3(false)
  }

  console.log(watch())
  const onSubmit = async (data: any) => {
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await addAddress(
        emailStepper,
        dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual,
        data.direccion1,
        data.direccion2,
        data.postalCode,
        data.departament,
        data.province,
        data.district,
        data.populatedCenter,
        data.estado,
        data.delegacion,
        data.colonia,
        data.municipio,
        data.city,
        data.nroExt,
        data.nroInt,
        lastShowedStep == 'Step_9' ? true : false
      )
      if (response.data.message.msgId == '0') {
        if (lastShowedStep == 'Step_9') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }

  useEffect(() => {
    if (idNivel2 != 0) {
      traerLocalizacionesNivel2()
    }
  }, [idNivel2])

  useEffect(() => {
    if (idNivel3 != 0) {
      traerLocalizacionesNivel3()
    }
  }, [idNivel3])

  return (
    <form className='d-flex flex-column gap-2' onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label fs-5 text-center w-100 m-0'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP9.LABEL'})}
      </label>
      <br />

      {/* direccion1 */}
      <label className='form-label fs-6' htmlFor='direccion1'>
        {intl.formatMessage({id: 'REGISTER.ADDRESS.ADDRES1'})} :
      </label>
      <input
        id='direccion1'
        className='form-control form-control-lg'
        type='text'
        {...register('direccion1', {
          required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
        })}
      />
      {errors.direccion1 && <p className='text-danger'>{errors.direccion1.message?.toString()}</p>}

      {/* direccion2 */}
      <label className='form-label fs-6' htmlFor='direccion2'>
        {intl.formatMessage({id: 'REGISTER.ADDRESS.ADDRES2'})} :
      </label>
      <input
        id='direccion2'
        className='form-control form-control-lg'
        type='text'
        {...register('direccion2')}
      />

      {/* NumberExt */}
      <label className='form-label fs-6' htmlFor='nroExt'>
        {intl.formatMessage({id: 'REGISTER.ADDRESS.NUMBEREXT'})} :
      </label>
      <input
        id='nroExt'
        className='form-control form-control-lg'
        type='text'
        {...register('nroExt', {
          required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
          pattern: {
            value: /^[0-9]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ONLY_NUMBERS'}),
          },
        })}
      />
      {errors.nroExt && <p className='text-danger'>{errors.nroExt.message?.toString()}</p>}

      {/* NumberInt */}
      <label className='form-label fs-6' htmlFor='nroInt'>
        {intl.formatMessage({id: 'REGISTER.ADDRESS.NUMBERINT'})} :
      </label>
      <input
        id='nroInt'
        className='form-control form-control-lg'
        type='text'
        {...register('nroInt', {
          pattern: {
            value: /^[0-9]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ONLY_NUMBERS'}),
          },
        })}
      />
      {errors.nroInt && <p className='text-danger'>{errors.nroInt.message?.toString()}</p>}

      {paisStepper && paisStepper === 'MX' ? (
        <>
          {/* City */}
          <label className='form-label fs-6' htmlFor='city'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.CITY'})} :
          </label>
          <input
            id='city'
            className='form-control form-control-lg'
            type='text'
            {...register('city', {
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            })}
          />
          {errors.city && <p className='text-danger'>{errors.city.message?.toString()}</p>}

          {/* Estado */}
          <label className='form-label fs-6' htmlFor='estado'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.STATE'})} :
          </label>
          <Controller
            control={control}
            name='estado'
            rules={{
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            }}
            render={({field: {onChange, value}}) => (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-estado'
                  variant='light'
                  className='form-control form-control-lg'
                >
                  {value
                    ? localizacionesNivel1.registro.find((item) => item.IdLocalizacion === idNivel2)
                        ?.Descripcion || 'Seleccione'
                    : 'Seleccione'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                  {/* Opción por defecto */}
                  <Dropdown.Item
                    as='button'
                    onClick={(e) => {
                      e.preventDefault()
                      onChange('')
                      setIdNivel2(0)
                      setValue('delegacion', '')
                    }} // Establecer valor vacío
                  >
                    Seleccione
                  </Dropdown.Item>

                  {/* Mapeo de opciones de localizacionesNivel1 */}
                  {localizacionesNivel1.registro &&
                    localizacionesNivel1.registro.map((item, index) => (
                      <Dropdown.Item
                        key={item.IdLocalizacion}
                        as='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onChange(item.IdLocalizacion)
                          setIdNivel2(item.IdLocalizacion)
                          setValue('delegacion', '')
                        }} // Establecer el valor seleccionado
                      >
                        {item.Descripcion}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          />
          {errors.estado && <p className='text-danger'>{errors.estado?.message?.toString()}</p>}

          {/* Delegacion */}
          <label className='form-label fs-6' htmlFor='delegacion'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.DELEGATION'})} :
          </label>
          <Controller
            control={control}
            name='delegacion'
            rules={{
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            }}
            render={({field: {onChange, value}}) => (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-delegacion'
                  variant='light'
                  className='form-control form-control-lg'
                >
                  {value
                    ? localizacionesNivel2.registro.find((item) => item.IdLocalizacion === idNivel3)
                        ?.Descripcion || 'Seleccione'
                    : 'Seleccione'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                  {/* Opción por defecto */}
                  <Dropdown.Item
                    as='button'
                    onClick={(e) => {
                      e.preventDefault()
                      onChange('')
                      setIdNivel3(0)
                    }} // Establecer valor vacío
                  >
                    Seleccione
                  </Dropdown.Item>

                  {/* Mapeo de opciones de localizacionesNivel1 */}
                  {localizacionesNivel2.registro &&
                    localizacionesNivel2.registro.map((item) => (
                      <Dropdown.Item
                        key={item.IdLocalizacion}
                        as='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onChange(item.IdLocalizacion)
                          setIdNivel3(item.IdLocalizacion)
                        }} // Establecer el valor seleccionado
                      >
                        {item.Descripcion}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          />
          {loadingNivel2 && (
            <div className='text-center'>
              <Spinner animation='border' variant='primary' size='sm' />
            </div>
          )}
          {errors.delegacion && (
            <p className='text-danger'>{errors.delegacion?.message?.toString()}</p>
          )}

          {/* Municipio */}
          <label className='form-label fs-6' htmlFor='municipio'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.MUNICIPALITY'})} :
          </label>
          <input
            id='municipio'
            className='form-control form-control-lg'
            type='text'
            {...register('municipio', {
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            })}
          />
          {errors.municipio && (
            <p className='text-danger'>{errors.municipio?.message?.toString()}</p>
          )}

          {/* Colonia */}
          <label className='form-label fs-6' htmlFor='colonia'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.COLONY'})} :
          </label>
          <input
            id='colonia'
            className='form-control form-control-lg'
            type='text'
            {...register('colonia', {
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            })}
          />
          {errors.colonia && <p className='text-danger'>{errors.colonia?.message?.toString()}</p>}
        </>
      ) : (
        <>
          {/* populatedCenter */}
          <label className='form-label fs-6' htmlFor='populatedCenter'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.POPCENTER'})} :
          </label>
          <input
            id='populatedCenter'
            className='form-control form-control-lg'
            type='text'
            {...register('populatedCenter', {
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            })}
          />
          {errors.populatedCenter && (
            <p className='text-danger'>{errors.populatedCenter?.message?.toString()}</p>
          )}
          {/* departament */}
          <label className='form-label fs-6' htmlFor='departament'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.DEPARTMENT'})} :
          </label>
          <Controller
            control={control}
            name='departament'
            rules={{
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            }}
            render={({field: {onChange, value}}) => (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-departament'
                  variant='light'
                  className='form-control form-control-lg'
                >
                  {value
                    ? localizacionesNivel1.registro.find((item) => item.IdLocalizacion === idNivel2)
                        ?.Descripcion || 'Seleccione'
                    : 'Seleccione'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                  {/* Opción por defecto */}
                  <Dropdown.Item
                    as='button'
                    onClick={(e) => {
                      e.preventDefault()
                      onChange('')
                      setIdNivel2(0)
                      setValue('province', '')
                      setValue('district', '')
                    }} // Establecer valor vacío
                  >
                    Seleccione
                  </Dropdown.Item>

                  {/* Mapeo de opciones de localizacionesNivel1 */}
                  {localizacionesNivel1.registro &&
                    localizacionesNivel1.registro.map((item, index) => (
                      <Dropdown.Item
                        key={item.IdLocalizacion}
                        as='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onChange(item.IdLocalizacion)
                          setIdNivel2(item.IdLocalizacion)
                          setValue('province', '')
                          setValue('district', '')
                        }} // Establecer el valor seleccionado
                      >
                        {item.Descripcion}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          />
          {errors.departament && (
            <p className='text-danger'>{errors.departament?.message?.toString()}</p>
          )}

          {/* province */}
          <label className='form-label fs-6' htmlFor='province'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.PROVINCE'})} :
          </label>
          <Controller
            control={control}
            name='province'
            rules={{
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            }}
            render={({field: {onChange, value}}) => (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-province'
                  variant='light'
                  className='form-control form-control-lg'
                >
                  {value
                    ? localizacionesNivel2.registro.find((item) => item.IdLocalizacion === idNivel3)
                        ?.Descripcion || 'Seleccione'
                    : 'Seleccione'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                  {/* Opción por defecto */}
                  <Dropdown.Item
                    as='button'
                    onClick={(e) => {
                      e.preventDefault()
                      onChange('')
                      setIdNivel3(0)
                      setValue('district', '')
                    }} // Establecer valor vacío
                  >
                    Seleccione
                  </Dropdown.Item>

                  {/* Mapeo de opciones de localizacionesNivel1 */}
                  {localizacionesNivel2.registro &&
                    localizacionesNivel2.registro.map((item) => (
                      <Dropdown.Item
                        key={item.IdLocalizacion}
                        as='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onChange(item.IdLocalizacion)
                          setIdNivel3(item.IdLocalizacion)
                          setValue('district', '')
                        }} // Establecer el valor seleccionado
                      >
                        {item.Descripcion}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          />
          {loadingNivel2 && (
            <div className='text-center'>
              <Spinner animation='border' variant='primary' size='sm' />
            </div>
          )}
          {errors.province && <p className='text-danger'>{errors.province?.message?.toString()}</p>}

          {/* district */}
          <label className='form-label fs-6' htmlFor='district'>
            {intl.formatMessage({id: 'REGISTER.ADDRESS.DISTRICT'})} :
          </label>
          <Controller
            control={control}
            name='district'
            rules={{
              required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            }}
            render={({field: {onChange, value}}) => (
              <Dropdown>
                <Dropdown.Toggle
                  id='dropdown-district'
                  variant='light'
                  className='form-control form-control-lg'
                >
                  {value
                    ? localizacionesNivel3.registro.find((item) => item.IdLocalizacion === idNivel4)
                        ?.Descripcion || 'Seleccione'
                    : 'Seleccione'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                  {/* Opción por defecto */}
                  <Dropdown.Item
                    as='button'
                    onClick={(e) => {
                      e.preventDefault()
                      onChange('')
                      setIdNivel4(0)
                    }} // Establecer valor vacío
                  >
                    Seleccione
                  </Dropdown.Item>

                  {/* Mapeo de opciones de localizacionesNivel1 */}
                  {localizacionesNivel3.registro &&
                    localizacionesNivel3.registro.map((item) => (
                      <Dropdown.Item
                        key={item.IdLocalizacion}
                        as='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onChange(item.IdLocalizacion)
                          setIdNivel4(item.IdLocalizacion)
                        }} // Establecer el valor seleccionado
                      >
                        {item.Descripcion}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          />
          {loadingNivel3 && (
            <div className='text-center'>
              <Spinner animation='border' variant='primary' size='sm' />
            </div>
          )}
          {errors.district && <p className='text-danger'>{errors.district?.message?.toString()}</p>}
        </>
      )}

      {/* Postal code */}
      <label className='form-label fs-6' htmlFor='postalCode'>
        {intl.formatMessage({id: 'REGISTER.POSTALCODE'})} :
      </label>
      <input
        id='postalCode'
        className='form-control form-control-lg'
        type='text'
        {...register('postalCode', {
          required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
          pattern: {
            value: /^[a-zA-Z0-9]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ALPHANUMERIC'}),
          },
        })}
      />
      {errors.postalCode && <p className='text-danger'>{errors.postalCode.message?.toString()}</p>}

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </form>
  )
}

export default StepNine
