import {useNavigate} from 'react-router-dom'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import {useForm} from 'react-hook-form'
import { useIntl } from 'react-intl'
const StepFifteen = ({
  prevStep,
  nextStep,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  lastShowedStep: string
}) => {
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const navigate = useNavigate()
  const intl = useIntl()
  const {handleSubmit} = useForm()

  const onSubmit = async (data: any) => {
    if (lastShowedStep == 'Step_15') {
      navigate('/auth/login')
    } else {
      nextStep()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <h1 className='text-center'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LABEL'})}</h1> */}
      <div className='d-flex justify-content-center '>
        <i className='bi bi-envelope fs-5tx text-success'></i>
      </div>
      <h1 className='text-center'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP15.LABEL'})}</h1>
      <p className='text-center fs-4'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP15.LABEL1'}, {email: emailStepper})}
      </p>

      <div className='d-flex flex-column justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP15.LABEL2'})}
        </button>
        <button type='button' className='btn btn-light-primary mt-4'>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP15.LABEL3'})}
        </button>
      </div>
    </form>
  )
}

export default StepFifteen
