import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import {enviarCodigoEmail, verifyCode} from '../../core/_requests'
import Swal from 'sweetalert2'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import { useNavigate } from 'react-router-dom'
const StepTwo = ({nextStep,goAnyStep}: { nextStep: () => void,goAnyStep: (step: number) => void }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [contador, setContador] = useState(0)

  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)

  const setTelephone = useStepperStore((state) => state.setTelephone)
  const setPais = useStepperStore((state) => state.setCountry)
  const setAccountType = useStepperStore((state) => state.setAccountType)

  const reenviarCodigo = async (data: any) => {
    try {
      setLoading2(true)
      const response = await enviarCodigoEmail(emailStepper,idOrgSaasStepper)
      if (response.data.message.msgId == '0') {
        setContador(30)
        setLoading2(false)
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      } else {
        setLoading2(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      setLoading2(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
        showConfirmButton: true,
      })
    }
  }

  const verificarCodigo = async () => {
    if (code.length == 0) {
      setShowMessage(true)
      return
    }
    setShowMessage(false)
    setLoading(true)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await verifyCode(emailStepper, code,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      if (response.data.message.msgId == '0') {
        setLoading(false)
        nextStep()
      } else if (response.data.message.msgId == '1') {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      } else if (response.data.message.msgId == '2') {
        if (response.data.registro.Celular) setTelephone(response.data.registro.Celular)
        if (response.data.registro.IdPais) setPais(response.data.registro.IdPais)
        if (response.data.registro.IdTipoUsuario) setAccountType(Number(response.data.registro.IdTipoUsuario))
        setLoading(false)
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonText: 'Continuar',
        }).then((result) => {
          if (result.isDenied) {
            if (response.data.interaccion?.IdActividad == 'B002') {
              goAnyStep(2)
            } else if (response.data.interaccion?.IdActividad == 'B004') {
              goAnyStep(3)
            } else if (response.data.interaccion?.IdActividad == 'B006') {
              goAnyStep(4)
            } else if (response.data.interaccion?.IdActividad == 'B008') {
              goAnyStep(5)
            } else if (response.data.interaccion?.IdActividad == 'B010') {
              goAnyStep(6)
            } else if (response.data.interaccion?.IdActividad == 'B012') {
              goAnyStep(7)
            } else if (response.data.interaccion?.IdActividad == 'I001') {
              goAnyStep(8)
            } else if (response.data.interaccion?.IdActividad == 'V001') {
              goAnyStep(9)
            } else if (response.data.interaccion?.IdActividad == 'V101') {
              goAnyStep(11)
            } else if (response.data.interaccion?.IdActividad == 'V103') {
              goAnyStep(12)
            } else if (response.data.interaccion?.IdActividad == 'V201') {
              goAnyStep(13)
            } else if (response.data.interaccion?.IdActividad == 'V301') {
              goAnyStep(14)
            }
          }
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
        showConfirmButton: true,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (contador > 0) {
      timer = setTimeout(() => setContador((prev) => prev - 1), 1000)
    }
    return () => clearTimeout(timer) // Limpia el intervalo al desmontar
  }, [contador])

  return (
    <div>
      {/* <h5 className='text-center'>
            ¿Ya tienes una cuenta?
            <Link className='ms-2' to='/auth/login'>
              Iniciar Sesión
            </Link>
          </h5> */}
      <label className='form-label fs-5' htmlFor='code'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP2.LABEL'})}
      </label>
      <input
        id='code'
        className='form-control'
        type='text'
        value={code}
        onChange={(e) => setCode(e.target.value.trim())}
      />
      {showMessage && <p className='text-danger'>{intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Code'})}</p>}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-sm btn-success mt-4'
          disabled={contador > 0}
          onClick={reenviarCodigo}
        >
          {loading2 ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>{intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : contador > 0 ? (
            `${intl.formatMessage({id: 'AUTH.REGISTER.STEP2.RESEND'})} ${contador} ${intl.formatMessage({id: 'AUTH.REGISTER.STEP2.SECOND'})}`
          ) : (
            intl.formatMessage({id: 'AUTH.REGISTER.STEP2.RESEND'})
          )}
        </button>
      </div>
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button onClick={verificarCodigo} className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>{intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH.REGISTER.STEP2.VERIFY'})
          )}
        </button>
      </div>
    </div>
  )
}

export default StepTwo
