import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {DocumentTypeResponseModel} from '../../core/_models'
import {saveDocumentIdentity} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'

const StepThirteen = ({
  prevStep,
  nextStep,
  tiposDoc,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  tiposDoc: DocumentTypeResponseModel
  lastShowedStep: string
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()


  const intl = useIntl()
  const navigate = useNavigate()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)

  const [loading, setLoading] = useState(false)
  const [base64Frontal, setBase64Frontal] = useState('')
  const [mimeFrontal, setMimeFrontal] = useState('')
  const [mimeTrasera, setMimeTrasera] = useState('')
  const [base64Trasera, setBase64Trasera] = useState('')
  const [previewFrontal, setPreviewFrontal] = useState<string | null>(null)
  const [previewTrasera, setPreviewTrasera] = useState<string | null>(null)

  const handleFileChange = (event: any, setBase64: any, setMime: any, setPreview: any) => {
    const file = event.target.files[0]
    console.log(file)
    if (!file) {
      setPreview(null)
      // Si no hay archivo (por ejemplo, al cancelar la selección), no hace nada
      return
    }

    const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.doc)$/i
    if (!allowedExtensions.exec(file.name)) {
      Swal.fire({
        icon: 'error',
        title: 'Archivo no permitido',
        text: intl.formatMessage({id: 'VALIDATION.TYPE.FILES'}),
      })
      event.target.value = '' // Limpia el input
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      const result = reader.result
      if (!result) return
      const base64String = result.toString().split(',')[1]
      const mime = result.toString().split(',')[0].split(';')[0].split(':')[1]
      setBase64(base64String)
      setMime(mime)
      setPreview(URL.createObjectURL(file))
    }
    reader.readAsDataURL(file)
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const {data: response} = await saveDocumentIdentity(emailStepper, dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual,lastShowedStep == 'Step_13' ? true : false, [
        {
          FileTipoMime: mimeFrontal,
          FileBase64: base64Frontal,
          FileNombre: data.frontal[0].name,
        },
        {
          FileTipoMime: mimeTrasera,
          FileBase64: base64Trasera,
          FileNombre: data.back[0].name,
        },
      ])
      if (response.message.msgId == '0') {
        if (lastShowedStep == 'Step_13') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
      setLoading(false)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label w-100' htmlFor='frontal-image-id'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL4'})}</h5>
        <input
          id='frontal-image-id'
          type='file'
          className='form-control'
          accept='.pdf, .jpg, .jpeg, .png, .doc'
          {...register('frontal', {
            required: {
              value: true,
              message: intl.formatMessage({id: 'VALIDATION.REQUIRED'}),
            },
          })}
          onChange={(e) => handleFileChange(e, setBase64Frontal, setMimeFrontal, setPreviewFrontal)}
        />
        {previewFrontal ? (
          <div className='text-center mt-5'>
            <img src={previewFrontal} width={200} height={200} />
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <i className='fa-regular fa-image' style={{fontSize: '200px'}}></i>
          </div>
        )}
        {errors.frontal && <p className='text-danger'>{errors.frontal.message?.toString()}</p>}
      </label>

      <label className='form-label w-100' htmlFor='back-image-id'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL5'})}</h5>
        <input
          id='back-image-id'
          type='file'
          className='form-control'
          accept='.pdf, .jpg, .jpeg, .png, .doc'
          {...register('back', {
            required: {
              value: true,
              message: intl.formatMessage({id: 'VALIDATION.REQUIRED'}),
            },
          })}
          onChange={(e) => handleFileChange(e, setBase64Trasera, setMimeTrasera, setPreviewTrasera)}
        />
        {previewTrasera ? (
          <div className='text-center mt-5'>
            <img src={previewTrasera} width={200} height={200} />
          </div>
        ) : (
          <div className='d-flex justify-content-center align-items-center mt-5'>
            <i className='fa-regular fa-image' style={{fontSize: '200px'}}></i>
          </div>
        )}
        {errors.back && <p className='text-danger'>{errors.back.message?.toString()}</p>}
      </label>

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})
          )}
        </button>
      </div>
    </form>
  )
}
export default StepThirteen
