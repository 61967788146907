import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {DocumentTypeResponseModel} from '../../core/_models'
import {addDataDocumentIdentity} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

const StepTwelve = ({
  prevStep,
  nextStep,
  tiposDoc,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  tiposDoc: DocumentTypeResponseModel
  lastShowedStep: string
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    watch,
  } = useForm()

  const fechaEmision = watch('fechaEmision')

  const intl = useIntl()
  const navigate = useNavigate()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const typeAccountStepper = useStepperStore((state) => state.dataStepper.accountType)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await addDataDocumentIdentity(
        emailStepper,
        dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual,
        data.IdType,
        data.nroDoc,
        new Date(data.fechaEmision).toISOString(),
        new Date(data.fechaCaducidad).toISOString(),
        lastShowedStep == 'Step_12' ? true : false
      )
      if (response.data.message.msgId == '0') {
        if (lastShowedStep == 'Step_12') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
      setLoading(false)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
      setLoading(false)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {typeAccountStepper === 2 && (
        <label className='fw-bold fs-4 w-100 mb-2'>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP12.LABEL5'})}
        </label>
      )}
      <label className='form-label w-100' htmlFor='IdType'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL3'})}</h5>
        <Controller
          control={control}
          name='IdType'
          rules={{
            required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
          }}
          render={({field: {onChange, value}}) => (
            <Dropdown>
              <Dropdown.Toggle
                id='dropdown-IdType'
                variant='light'
                className='form-control form-control-lg '
              >
                {value
                  ? tiposDoc.registro.find((item) => item.IdTipoDocumento === value)?.Descripcion ||
                    'Seleccione'
                  : 'Seleccione'}
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100' style={{maxHeight: '250px', overflow: 'auto'}}>
                {/* Opción por defecto */}
                <Dropdown.Item
                  as='button'
                  onClick={(e) => {
                    e.preventDefault()
                    onChange('')
                  }} // Establecer valor vacío
                >
                  Seleccione
                </Dropdown.Item>

                {/* Mapeo de opciones de localizacionesNivel1 */}
                {tiposDoc.registro &&
                  tiposDoc.registro.map((item, index) => (
                    <Dropdown.Item
                      key={item.IdTipoDocumento}
                      as='button'
                      onClick={(e) => {
                        e.preventDefault()
                        onChange(item.IdTipoDocumento)
                      }} // Establecer el valor seleccionado
                    >
                      {item.Descripcion}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        />
        {errors.IdType && <p className='text-danger'>{errors.IdType.message?.toString()}</p>}
      </label>

      <label className='form-label w-100' htmlFor='nroDoc'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL6'})}</h5>
        <input
          id='nroDoc'
          type='text'
          className='form-control'
          {...register('nroDoc', {
            required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
            maxLength: {
              value: 13,
              message: intl.formatMessage({id: 'VALIDATION.LENGTH'}, {min: 13}),
            },
          })}
        />
        {errors.nroDoc && <p className='text-danger'>{errors.nroDoc.message?.toString()}</p>}
      </label>

      <label className='form-label w-100' htmlFor='fechaEmision'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL7'})}</h5>
        <input
          id='fechaEmision'
          type='date'
          className='form-control'
          {...register('fechaEmision', {
            required: {
              value: true,
              message: intl.formatMessage({id: 'VALIDATION.REQUIRED'}),
            },
          })}
        />
        {errors.fechaEmision && (
          <p className='text-danger'>{errors.fechaEmision.message?.toString()}</p>
        )}
      </label>

      <label className='form-label w-100' htmlFor='fechaCaducidad'>
        <h5>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL8'})}</h5>
        <input
          id='fechaCaducidad'
          type='date'
          className='form-control'
          {...register('fechaCaducidad', {
            required: {
              value: true,
              message: intl.formatMessage({id: 'VALIDATION.REQUIRED'}),
            },
            validate: (value) =>
              (fechaEmision && value > fechaEmision) ||
              intl.formatMessage({id: 'VALIDATION.EXPIRATION.IDENTITYDATE'}),
          })}
        />
        {errors.fechaCaducidad && (
          <p className='text-danger'>{errors.fechaCaducidad.message?.toString()}</p>
        )}
      </label>

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})
          )}
        </button>
      </div>
    </form>
  )
}
export default StepTwelve
