import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {addCountry} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useForm} from 'react-hook-form'
const StepTen = ({prevStep, nextStep}: {prevStep: () => void; nextStep: () => void}) => {
  const intl = useIntl()

  const backStep = () => {
    prevStep()
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm()

  const onSubmit = async (data: any) => {
    nextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className='text-center'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LABEL'})}</h1>
      <div className='d-flex justify-content-center'>
        <KTSVG
          path='/media/icons/duotune/general/gen051.svg'
          className='svg-icon-success svg-icon-5hx'
        />
      </div>
      <p className='text-center fs-4'>
      {intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LABEL2'})}
      </p>

      <ul className='fw-bold fs-4'>
        <li>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LI1'})}</li>
        <li>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LI2'})}</li>
        <li>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LI3'})}</li>
        <li>{intl.formatMessage({id: 'AUTH.REGISTER.STEP10.LI4'})}</li>
      </ul>

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>{intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}</button>
      </div>
    </form>
  )
}

export default StepTen
