import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {PaisesResponseModel} from '../../core/_models'
import {addCountry} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'

interface paisesModel {
  IdPais: string
  Nombre: string
  CodigoPhone: string
  UrlPais: string
  Activo: boolean
}
const StepFour = ({
  prevStep,
  nextStep,
  paises,
}: {
  prevStep: () => void
  nextStep: () => void
  paises: PaisesResponseModel
}) => {
  const intl = useIntl()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const setCountryStepper = useStepperStore((state) => state.setCountry)
  const [selectedCountry, setSelectedCountry] = useState<paisesModel | undefined>({
    IdPais: 'PE',
    Nombre: 'Perú',
    CodigoPhone: '+51',
    UrlPais: '/media/flags/peru.svg',
    Activo: true,
  })
  const [selectedIso, setSelectedIso] = useState('PE')

  const handleSelect = (country: string) => {
    setSelectedCountry(paises.registro.find((p) => p.IdPais === country))
    setSelectedIso(country)
    console.log('País seleccionado:', country)
  }

  const backStep = () => {
    prevStep()
  }

  const saveCountry = async () => {
    setCountryStepper(selectedIso)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const {data} = await addCountry(emailStepper, selectedIso,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      if (data.message.msgId == '0') {
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${data.message.msgTxt}`,
          showConfirmButton: true,
        })
        nextStep()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }
  return (
    <div>
      <label className='form-label fs-5' htmlFor='code'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP4.LABEL'})}
      </label>
      <Dropdown className='w-100'>
        <Dropdown.Toggle className='w-100' variant='secondary' id='dropdown-paises'>
          <img
            src={toAbsoluteUrl(selectedCountry?.UrlPais ?? '')}
            width={20}
            height={20}
            alt={selectedIso}
            className='me-3'
          />
          {selectedCountry?.Nombre}
        </Dropdown.Toggle>

        <Dropdown.Menu className='w-100' style={{maxHeight: '150px', overflow: 'auto'}}>
          {paises &&
            paises.registro &&
            paises.registro.map((p) => (
              <Dropdown.Item key={p.IdPais} onClick={() => handleSelect(p.IdPais)}>
                <img
                  src={toAbsoluteUrl(p.UrlPais)}
                  width={20}
                  height={20}
                  alt={p.Nombre}
                  className='me-3'
                />
                {p.Nombre}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
        {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button onClick={saveCountry} className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </div>
  )
}

export default StepFour
