import React from 'react'

const ProgressBar = ({currentStep, allSteps}: {currentStep: number; allSteps: any[]}) => {
  const progressBarStyle = {
    width: `${(currentStep / allSteps.length) * 100}%`,
    transition: 'width 0.5s ease-in-out',
  }

  return (
    <div className='mt-5 d-flex fw-bold fs-6 justify-content-center'>
      <div className='progress w-250px' style={{position: 'relative'}}>
        <span
          className='progress-bar bg-primary progress-bar-striped'
          style={progressBarStyle}
          aria-valuenow={currentStep}
          aria-valuemin={0}
          aria-valuemax={allSteps.length}
          role='progressbar'
        ></span>
      </div>
    </div>
  )
}

export default ProgressBar
