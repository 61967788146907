import {useIntl} from 'react-intl'
import {useState} from 'react'
import {acceptValidationIdentity} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useForm} from 'react-hook-form'
import {DocumentTypeResponseModel} from '../../core/_models'
import {useNavigate} from 'react-router-dom'
const StepEleven = ({
  prevStep,
  nextStep,
  tiposDoc,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  tiposDoc: DocumentTypeResponseModel
  lastShowedStep: string
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const OrgSaasNameStepper = useStepperStore((state) => state.dataStepper.orgSaasName)

  const {handleSubmit} = useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await acceptValidationIdentity(emailStepper, dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual, true)
      if (response.data.message.msgId == '0') {
        if (lastShowedStep == 'Step_11') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
      setLoading(false)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className='text-center'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL'})}</h1>
      <p className='text-center fs-4'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP11.LABEL2'})}</p>
      <div className='d-flex justify-content-center'>
        <i className='fa-solid fa-id-card text-success' style={{fontSize: '90px'}}></i>
      </div>

      <div className='bg-secondary p-4 mt-10'>
        <p className='m-0 fs-6'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP11.TEXT1'})}
        </p>
      </div>
      <div className='bg-secondary p-4 mt-10'>
        <p className='m-0 fs-6'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP11.TEXT2'}, {org:OrgSaasNameStepper})}
        </p>
      </div>

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH_STEPPER_BTNSTART'})
          )}
        </button>
      </div>
    </form>
  )
}

export default StepEleven
