import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {confirmationCodeSms, sendVerificationCodeSms} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useEffect, useState} from 'react'
const StepSix = ({prevStep, nextStep}: {prevStep: () => void; nextStep: () => void}) => {
  const intl = useIntl()
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const telephoneStepper = useStepperStore((state) => state.dataStepper.telephone)
  const [contador, setContador] = useState(0)

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const backStep = () => {
    prevStep()
  }

  const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;

  const reenviarCodigo = async (data: any) => {
    console.log(telephoneStepper)
    try {
      setLoading2(true)
      
      const response = await sendVerificationCodeSms(emailStepper, telephoneStepper,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      if (response.data.message.msgId == '0') {
        setContador(30)
        setLoading2(false)
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      } else {
        setLoading2(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      setLoading2(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
        showConfirmButton: true,
      })
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      const response = await confirmationCodeSms(emailStepper, data.codetelefono,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      if (response.data.message.msgId == '0') {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
        nextStep()
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (contador > 0) {
      timer = setTimeout(() => setContador((prev) => prev - 1), 1000)
    }
    return () => clearTimeout(timer) // Limpia el intervalo al desmontar
  }, [contador])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label fs-5' htmlFor='codetel'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP6.LABEL'})}
      </label>
      <br />
      <p className='text-center'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP6.LABEL2'})}</p>
      <input
        id='codetel'
        className='form-control form-control-lg'
        type='text'
        {...register('codetelefono', {
          required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
          maxLength: {value: 6, message: intl.formatMessage({id: 'VALIDATION.LENGTHD'}, {min: 6})},
          minLength: {value: 6, message: intl.formatMessage({id: 'VALIDATION.LENGTHD'}, {min: 6})},
        })}
      />
      {errors.codetelefono && (
        <p className='text-danger'>{errors.codetelefono.message?.toString()}</p>
      )}
      <div className='text-center'>
        <button
          type='button'
          className='btn btn-sm btn-success mt-4'
          disabled={contador > 0}
          onClick={reenviarCodigo}
        >
          {loading2 ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : contador > 0 ? (
            `${intl.formatMessage({
              id: 'AUTH.REGISTER.STEP2.RESEND',
            })} ${contador} ${intl.formatMessage({id: 'AUTH.REGISTER.STEP2.SECOND'})}`
          ) : (
            intl.formatMessage({id: 'AUTH.REGISTER.STEP2.RESEND'})
          )}
        </button>
      </div>
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH.REGISTER.STEP2.VERIFY'})
          )}
        </button>
      </div>
    </form>
  )
}

export default StepSix
