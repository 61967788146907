import {Link, useNavigate} from 'react-router-dom'
import {set, useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {createUser, enviarCodigoEmail} from '../../core/_requests'
import Swal from 'sweetalert2'
import {useStepperStore} from '../../../../stores/AuthStepperStore'

const StepOne = ({
  nextStep,
}: {
  nextStep: () => void
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const setEmail = useStepperStore((state) => state.setEmail)


  const intl = useIntl()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await createUser(data.email,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      console.log(response.data)
      if (response.data.message.msgId == '0') {
        setEmail(data.email)
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
        nextStep()
      }  else {
        setEmail(data.email)
        setLoading(false)
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error}`,
        showConfirmButton: true,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <h5 className='text-center'>
          ¿Ya tienes una cuenta?
          <Link className='ms-2' to='/auth/login'>
            Iniciar Sesión
          </Link>
        </h5> */}
      <label className='form-label fs-3' htmlFor='emailStepOne'>
        {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
      </label>
      <input
        id='emailStepOne'
        className='form-control'
        type='text'
        placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        {...register('email', {
          required: {
            value: true,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILREQUIRED'}),
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILFORMAT'}),
          },
        })}
      />
      {errors.email && <div className='text-danger fs-6'>{errors.email.message?.toString()}</div>}
      <label className='d-flex gap-2 my-4 w-100 justify-content-center align-items-center'>
        <input className='form-check-input border-gray-700' type='checkbox' />
        <p className='fw-bold w-50 border border-1 border-dark-subtle rounded p-2 m-0'>
          Al registrarte, aceptas nuestras Condiciones de uso y Política de privacidad
        </p>
      </label>
      <div className='text-center'>
        <button className='btn btn-primary mt-4'>
          {loading ? (
            <span>
              <span className='spinner-border spinner-border-sm me-2'></span>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}...
            </span>
          ) : (
            intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})
          )}
        </button>
      </div>

      <div className='mt-5 text-center'>
        <span>{intl.formatMessage({id: 'AUTH.REGISTER.ALTERNATIVE'})}</span>
        <div>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap pe-none '
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px'
            />
          </a>
        </div>
      </div>
    </form>
  )
}

export default StepOne
