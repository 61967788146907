/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'

import {sha256} from 'js-sha256'
// import {getToken} from 'firebase/messaging'
// import {messaging} from '../../../../firebase'
import Swal from 'sweetalert2'

const initialValues = {
  email: '',
  password: '',
}

let email = ''

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const vapidKey = process.env.REACT_APP_VADIPKEY_FB
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showModalActive, setShowModalActive] = useState(false)
  const [msg, setMsg] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILFORMAT'}))
      .min(3, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMIN'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMAX'}))
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILREQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMIN'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMAX'}))
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDREQUIRED'})),
  })

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      //GUARDAR EL TOKEN
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;

      try {
        const {data: auth} = await login(
          values.email,
          sha256(process.env.REACT_APP_SALT + sha256(values.password)),
          dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual
        )
        email = values.email
        saveAuth(auth)

        // //CAMINO EXITOSO DE LOGIN
        if (auth.msg_id == '0') {
          // permissionStatus === 'default' && requestNotificationPermission()
          const {data: user} = await getUserByToken(auth.access_token_ob)
          setCurrentUser(user)
          navigate('/modules')

          // Mostrar mensaje de usuario autorizado
        } else if (auth.msg_id == '1') {
          saveAuth(undefined)
          setStatus(auth.msg_txt)
          setMsg(auth.msg_txt)
          setSubmitting(false)
          setLoading(false)

          // Mostrar mensaje de usuario inactivo
        } else if (auth.msg_id == '2') {
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${auth.msg_txt}`,
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Continuar con Registro',
          }).then((e) => {
            if (e.isConfirmed) {
              navigate('/auth/registration')
            }
          })
        }
      } catch (error) {
        console.log('el error')
        console.error(error)
        setLoading(false)
      }
    },
  })

  // const requestNotificationPermission = async () => {
  //   try {
  //     const permission = await Notification.requestPermission()
  //     setPermissionStatus(permission)

  //     let fcm_token = await getToken(messaging, {vapidKey})
  //     if (fcm_token) {
  //       console.log(fcm_token)
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Notificaciones activadas',
  //         text: 'Se han activado las notificaciones',
  //         timer: 2000,
  //       })
  //       //Actualizar el campo del usuario
  //     } else {
  //       console.log('No registration token available. Request permission to generate one')

  //       // Swal.fire({
  //       //   icon: 'error',
  //       //   title: 'No se ha podido activar las notificaciones',
  //       //   text: 'Se ha producido un error al activar las notificaciones',
  //       //   timer: 2000,
  //       // })
  //     }
  //   } catch (error) {
  //     console.error('Error during token generation:', error)
  //     // Swal.fire({
  //     //   icon: 'error',
  //     //   title: 'No se ha podido activar las notificaciones',
  //     //   text: 'Se ha producido un error al activar las notificaciones',
  //     //   timer: 2000,
  //     // })
  //   }
  // }

  return (
    <form
      className='form w-100'
      onSubmit={(e) => {
        formik.handleSubmit(e)
        // ;
        // sendEmail(e)
      }}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-1'>
        <h1 className='text-dark fw-bolder mb-3 display-6'>
          {intl.formatMessage({id: 'LOGIN.WELLCOME1'})}
        </h1>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{msg}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{intl.formatMessage({id: 'LOGIN.WELLCOME'})}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'LOGIN.TEXT.LABELEMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'LOGIN.TEXT.LABELEMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          {intl.formatMessage({id: 'LOGIN.TEXT.LABELCONTRASEÑA'})}
        </label>
        <div className='input-group position-relative'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder={intl.formatMessage({id: 'LOGIN.TEXT.LABELCONTRASEÑA'})}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-transparent rounded h-40px', {
              'is-invalid': formik.touched.password && formik.errors.password,
              'is-valid': formik.touched.password && !formik.errors.password,
            })}
          />
          <button
            className='btn btn-primary px-2 border border-1 border-gray-400 position-absolute end-0 h-40px'
            type='button'
            onClick={handleTogglePasswordVisibility}
            style={{zIndex: 5}}
          >
            {showPassword ? (
              <div className='w-100 h-100 d-flex align-items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-25px h-25px'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                  />
                </svg>
              </div>
            ) : (
              <div className='w-100 h-100 d-flex align-items-center '>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-25px h-25px'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                  />
                </svg>
              </div>
            )}
          </button>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        <Link to='/auth/forgot-password'>
          {intl.formatMessage({id: 'LOGIN.FORGOTPASSWORD'})}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => {
            setShowModalActive(showModalActive)
          }}
        >
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({id: 'LOGIN.TITLE'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOGIN.BTNWAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        {intl.formatMessage({id: 'LOGIN.TEXT.NOTMEMBER'})}{' '}
        <Link to='/auth/registration'>
          {intl.formatMessage({id: 'AUTH.GENERAL.SIGNUP_BUTTON'})}
        </Link>
      </div>
    </form>
  )
}
