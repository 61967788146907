import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'

const ThemeSwitcherDropdown: React.FC = () => {
  // Colores proporcionados en el archivo SCSS
  const colorSets = {
    primary: {
      base: '#009ef7',
      active: '#0095e8',
      light: '#f1faff',
      lightDark: '#212e48',
      inverse: '#ffffff',
      hover: '#007bb5', // Hover color
      focus: '#006fa3', // Focus color
      linkRgb: '0, 158, 247', // RGB color de enlace
      linkHoverRgb: '0, 123, 181', // RGB color de enlace al pasar el cursor
    },
    success: {
      base: '#50cd89',
      active: '#47be7d',
      light: '#e8fff3',
      lightDark: '#1c3238',
      inverse: '#ffffff',
      hover: '#40b578', // Hover color
      focus: '#2f9d5f', // Focus color
      linkRgb: '80, 205, 137', // RGB color de enlace
      linkHoverRgb: '64, 181, 120', // RGB color de enlace al pasar el cursor
    },
    danger: {
      base: '#f1416c',
      active: '#d9214e',
      light: '#fff5f8',
      lightDark: '#3a2434',
      inverse: '#ffffff',
      hover: '#e72f5b', // Hover color
      focus: '#c21c47', // Focus color
      linkRgb: '241, 65, 108', // RGB color de enlace
      linkHoverRgb: '231, 47, 91', // RGB color de enlace al pasar el cursor
    },
    warning: {
      base: '#ffc700',
      active: '#f1bc00',
      light: '#fff8dd',
      lightDark: '#392f28',
      inverse: '#ffffff',
      hover: '#e6b200', // Hover color
      focus: '#d99c00', // Focus color
      linkRgb: '255, 199, 0', // RGB color de enlace
      linkHoverRgb: '230, 178, 0', // RGB color de enlace al pasar el cursor
    },
  }

  const [currentColor, setCurrentColor] = useState<string>(() => {
    // Recuperar el color almacenado desde localStorage o usar el color azul por defecto
    const savedColor = localStorage.getItem('primaryColor')
    return savedColor || colorSets.primary.base // Por defecto es el color azul
  })

  // Función para cambiar el esquema de colores
  const changeColorScheme = (colorName: keyof typeof colorSets) => {
    const color = colorSets[colorName]

    // Aplicar las variables CSS globalmente
    document.documentElement.style.setProperty('--bs-primary', color.base)
    document.documentElement.style.setProperty('--bs-primary-active', color.active)
    document.documentElement.style.setProperty('--bs-primary-light', color.light)
    document.documentElement.style.setProperty('--bs-primary-light-dark', color.lightDark)
    document.documentElement.style.setProperty('--bs-primary-inverse', color.inverse)

    // Aplicar los colores RGB para enlaces
    document.documentElement.style.setProperty('--bs-link-color-rgb', color.linkRgb)
    document.documentElement.style.setProperty('--bs-link-hover-color-rgb', color.linkHoverRgb)

    document.documentElement.style.setProperty('--bs-success', colorSets.success.base)
    document.documentElement.style.setProperty('--bs-success-active', colorSets.success.active)
    document.documentElement.style.setProperty('--bs-success-light', colorSets.success.light)
    document.documentElement.style.setProperty(
      '--bs-success-light-dark',
      colorSets.success.lightDark
    )
    document.documentElement.style.setProperty('--bs-success-inverse', colorSets.success.inverse)

    document.documentElement.style.setProperty('--bs-danger', colorSets.danger.base)
    document.documentElement.style.setProperty('--bs-danger-active', colorSets.danger.active)
    document.documentElement.style.setProperty('--bs-danger-light', colorSets.danger.light)
    document.documentElement.style.setProperty('--bs-danger-light-dark', colorSets.danger.lightDark)
    document.documentElement.style.setProperty('--bs-danger-inverse', colorSets.danger.inverse)

    document.documentElement.style.setProperty('--bs-warning', colorSets.warning.base)
    document.documentElement.style.setProperty('--bs-warning-active', colorSets.warning.active)
    document.documentElement.style.setProperty('--bs-warning-light', colorSets.warning.light)
    document.documentElement.style.setProperty(
      '--bs-warning-light-dark',
      colorSets.warning.lightDark
    )
    document.documentElement.style.setProperty('--bs-warning-inverse', colorSets.warning.inverse)

    // Colores hover y focus
    document.documentElement.style.setProperty('--bs-primary-hover', color.hover)
    document.documentElement.style.setProperty('--bs-primary-focus', color.focus)

    // Guardar el esquema de color completo en el localStorage
    const allColors = {
      primary: color,
      success: colorSets.success,
      danger: colorSets.danger,
      warning: colorSets.warning,
    }

    localStorage.setItem('colorScheme', JSON.stringify(allColors))

    // Actualizar el color base
    setCurrentColor(color.base)
  }

  useEffect(() => {
    // Al montar la aplicación, establecer el esquema de color desde localStorage o color por defecto
    const savedColors = localStorage.getItem('colorScheme')
    if (savedColors) {
      const colorScheme = JSON.parse(savedColors)

      // Usar solo el color que corresponde al currentColor
      const currentColorScheme = colorScheme.primary || colorSets.primary

      document.documentElement.style.setProperty('--bs-primary', currentColorScheme.base)
      document.documentElement.style.setProperty('--bs-primary-active', currentColorScheme.active)
      document.documentElement.style.setProperty('--bs-primary-light', currentColorScheme.light)
      document.documentElement.style.setProperty(
        '--bs-primary-light-dark',
        currentColorScheme.lightDark
      )
      document.documentElement.style.setProperty('--bs-primary-inverse', currentColorScheme.inverse)
      document.documentElement.style.setProperty('--bs-primary-hover', currentColorScheme.hover)
      document.documentElement.style.setProperty('--bs-primary-focus', currentColorScheme.focus)
      document.documentElement.style.setProperty('--bs-link-color-rgb', currentColorScheme.linkRgb)
      document.documentElement.style.setProperty(
        '--bs-link-hover-color-rgb',
        currentColorScheme.linkHoverRgb
      )

      setCurrentColor(currentColorScheme.base)
    } else {
      // Si no hay esquema de color guardado, usar el azul por defecto
      setCurrentColor(colorSets.primary.base)
      document.documentElement.style.setProperty('--bs-primary', colorSets.primary.base)
    }
  }, [])

  return (
    <div className='flex flex-col items-center'>
      <Dropdown>
        <Dropdown.Toggle variant='primary' id='themeSwitcherDropdown'>
          Color
        </Dropdown.Toggle>

        <Dropdown.Menu className='w-100'>
          <Dropdown.Item
            style={{color: '#009ef7'}}
            active={currentColor === colorSets.primary.base}
            onClick={() => changeColorScheme('primary')}
          >
            Azul
          </Dropdown.Item>
          <Dropdown.Item
            style={{color: '#50cd89'}}
            active={currentColor === colorSets.success.base}
            onClick={() => changeColorScheme('success')}
          >
            Verde
          </Dropdown.Item>
          <Dropdown.Item
            style={{color: '#f1416c'}}
            active={currentColor === colorSets.danger.base}
            onClick={() => changeColorScheme('danger')}
          >
            Rojo
          </Dropdown.Item>
          <Dropdown.Item
            style={{color: '#ffc700'}}
            active={currentColor === colorSets.warning.base}
            onClick={() => changeColorScheme('warning')}
          >
            Amarillo
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ThemeSwitcherDropdown
