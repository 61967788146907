import {useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {addPassword} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import {sha256} from 'js-sha256'
import Swal from 'sweetalert2'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
const StepSeven = ({
  prevStep,
  nextStep,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  lastShowedStep: string
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm()

  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  })

  const backStep = () => {
    prevStep()
  }

  const validatePassword = (value: string) => {
    setPasswordChecks({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
    })
  }

  const onSubmit = async (data: any) => {
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await addPassword(
        emailStepper,
        sha256(process.env.REACT_APP_SALT + sha256(data.password)),
        lastShowedStep == 'Step_7' ? true : false,
        dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual
      )
      if (response.data.message.msgId == '0') {
        if (lastShowedStep == 'Step_7') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label fs-5' htmlFor='password'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP7.LABEL'})}
      </label>

      <input
        type='email'
        id='email'
        autoComplete='username' // Atributo autocomplete para nombre de usuario
        style={{display: 'none'}} // Oculto pero accesible para el navegador
        {...register('email')}
      />
      <input
        id='password'
        className='form-control form-control-lg'
        type='password'
        autoComplete='new-password'
        {...register('password', {
          required: {
            value: true,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDREQUIRED'}),
          },
          minLength: {value: 8, message: intl.formatMessage({id: 'VALIDATION.LENGTHD'}, {min: 8})},
          pattern: {
            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            message: '',
          },
          onChange: (e) => validatePassword(e.target.value), // Llama a la función en cada cambio
        })}
      />
      {/* {errors.password && <p className='text-danger'>{errors.password.message?.toString()}</p>} */}
      <div className='mt-4'>
        <p className='fw-bold'>{intl.formatMessage({id: 'AUTH.REGISTER.STEP7.LABEL2'})}:</p>
        <ul>
          <li className={passwordChecks.length ? 'text-success' : 'text-danger'}>
            {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDLENGTH'})}
          </li>
          <li className={passwordChecks.uppercase ? 'text-success' : 'text-danger'}>
            {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMAYUS'})}
          </li>
          <li className={passwordChecks.lowercase ? 'text-success' : 'text-danger'}>
            {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDMINUS'})}
          </li>
          <li className={passwordChecks.number ? 'text-success' : 'text-danger'}>
            {intl.formatMessage({id: 'LOGIN.VALIDATION.PASSWORDNUM'})}
          </li>
        </ul>
      </div>
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </form>
  )
}

export default StepSeven
