/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {RegistroFuncionalidad, useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const [data, setData] = useState<RegistroFuncionalidad[]>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    if (currentUser?.registro.cat_rol) {
      setData(currentUser.registro.cat_rol.funcionalidad)
    }
  }, [])

  // function validarPorIdMenu(texto: string) {
  //   return data.some((item) => item.Descripcion.toLowerCase().includes(texto.toLowerCase()))
  // }

  function validarPorIdMenu(id: number) {
    return data.some((item) => item.menu !== null && item.menu.IdMenu === id)
  }

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.MENU'})}
          </span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title={intl.formatMessage({id: 'MENU.CATALOGO'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <div className='overflow-auto' style={{maxHeight: '400px'}}>
          {validarPorIdMenu(5) && (
            <SidebarMenuItem
              to='/menu/catalogo/cars'
              title={intl.formatMessage({id: 'CATALOGO.CAR'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(6) && (
            <SidebarMenuItem
              to='/menu/catalogo/centros'
              title={intl.formatMessage({id: 'CATALOGO.CENTER'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(7) && (
            <SidebarMenuItem
              to='/menu/catalogo/sociedad'
              title={intl.formatMessage({id: 'CATALOGO.SOCIETY'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(8) && (
            <SidebarMenuItem
              to='/menu/catalogo/clienteb2c'
              title='Clientes Individuales / Consumidores'
              // title={intl.formatMessage({id: 'CATALOGO.SOCIETY'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(9) && (
            <SidebarMenuItem
              to='/menu/catalogo/clienteb2b'
              title='Clientes Empresariales'
              // title={intl.formatMessage({id: 'CATALOGO.SOCIETY'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(10) && (
            <SidebarMenuItem
              to='/menu/catalogo/empleados'
              title='Empleados'
              // title={intl.formatMessage({id: 'CATALOGO.SOCIETY'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(11) && (
            <SidebarMenuItem
              to='/menu/catalogo/usuarios'
              title='Usuarios'
              // title={intl.formatMessage({id: 'CATALOGO.SOCIETY'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(12) && (
            <SidebarMenuItem
              to='/menu/catalogo/contactenos'
              title={intl.formatMessage({id: 'CATALOGO.CONTACTUS'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(13) && (
            <SidebarMenuItem
              to='/menu/catalogo/esqestatus'
              title={intl.formatMessage({id: 'CATALOGO.STATUSSCHEME'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(14) && (
            <SidebarMenuItem
              to='/menu/catalogo/grpconsentimiento'
              title={intl.formatMessage({id: 'CATALOGO.GROUPCONSENT'})}
              hasBullet={true}
            />
          )}
        </div>

        {/* <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>

      {(validarPorIdMenu(15) || validarPorIdMenu(16)) && (
        <SidebarMenuItemWithSub
          to='/crafted/accounts'
          title='Transacciones'
          icon='/media/icons/duotune/general/gen016.svg'
          fontIcon='bi-person'
        >
          {validarPorIdMenu(15) && (
            <SidebarMenuItem
              to='/menu/catalogo/reserva'
              title={intl.formatMessage({id: 'CATALOGO.BOOKING'})}
              hasBullet={true}
            />
          )}

          {/* <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
      <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      )}

      {(validarPorIdMenu(1) || validarPorIdMenu(1)) && (
        <SidebarMenuItemWithSub
          to='/crafted/accounts'
          title='Consultas'
          icon='/media/icons/duotune/general/gen023.svg'
          fontIcon='bi-person'
        ></SidebarMenuItemWithSub>
      )}

      {(validarPorIdMenu(16) || validarPorIdMenu(17)) && (
        <SidebarMenuItemWithSub
          to='/crafted/accounts'
          title='Configuración'
          icon='/media/icons/duotune/general/gen019.svg'
          fontIcon='bi-person'
        >
          {validarPorIdMenu(16) && (
            <SidebarMenuItem
              to='/menu/catalogo/especiftecnicas'
              title={intl.formatMessage({id: 'CATALOGO.TECSPECIFIC'})}
              hasBullet={true}
            />
          )}

          {validarPorIdMenu(17) && (
            <SidebarMenuItem
              to='/menu/catalogo/constante'
              title={intl.formatMessage({id: 'CATALOGO.CONSTANT'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
