import {useIntl} from 'react-intl'
import {useState} from 'react'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import {addAccountType} from '../../core/_requests'
import Swal from 'sweetalert2'
const StepThree = ({prevStep, nextStep}: {prevStep: () => void; nextStep: () => void}) => {
  const intl = useIntl()
  const setAccountType = useStepperStore((state) => state.setAccountType)
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const [tipoCuenta, setTipoCuenta] = useState(0)

  const backStep = () => {
    prevStep()
  }

  const cambiarTipoCuenta = (tipo: number) => {
    setTipoCuenta(tipo)
    setAccountType(tipo)
  }
  const saveAccountType = async () => {
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;
      const {data} = await addAccountType(
        emailStepper,
        dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual,
        tipoCuenta
      )
      if (data.message.msgId == '0') {
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${data.message.msgTxt}`,
          showConfirmButton: true,
        })
        nextStep()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }
  return (
    <div>
      <label className='form-label fs-5' htmlFor='code'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP3.LABEL'})}
      </label>
      <div className='d-flex justify-content-center align-items-center flex-column gap-5'>
        <button
          type='button'
          className={`btn btn-${
            tipoCuenta === 1 ? 'success' : 'light-success'
          } border border-2 border-success d-flex justify-content-center align-items-center flex-column gap-2 p-1`}
          style={{width: '100px', height: '100px'}}
          onClick={() => cambiarTipoCuenta(1)}
        >
          <i className='bi bi-person-fill p-0' style={{fontSize: '40px'}}></i>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP3.CARD1'})}
        </button>
        <button
          type='button'
          className={`btn btn-${
            tipoCuenta === 2 ? 'success' : 'light-success'
          } border border-2 border-success d-flex justify-content-center align-items-center flex-column gap-2 p-1`}
          style={{width: '100px', height: '100px'}}
          onClick={() => cambiarTipoCuenta(2)}
        >
          <i className='bi bi-building p-0' style={{fontSize: '40px'}}></i>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP3.CARD2'})}
        </button>
      </div>
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button
          onClick={saveAccountType}
          disabled={tipoCuenta === 0}
          className='btn btn-primary mt-4'
        >
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </div>
  )
}

export default StepThree
