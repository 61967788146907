import { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import { recuperarcontraseña } from '../core/_requests'
import Swal from 'sweetalert2'

export function ForgotPassword() {
  const intl = useIntl()
  const initialValues = {
    email: '',
  }
  const navigate = useNavigate()
  
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILFORMAT'}))
      .min(3, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMIN'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILMAX'}))
      .required(intl.formatMessage({id: 'LOGIN.VALIDATION.EMAILREQUIRED'})),
  })

  const [loading, setLoading] = useState(false)

  const [color, setColor] = useState<string>("")
  
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;
      try {
        setLoading(true)
        const {data: Recuperar} = await recuperarcontraseña(values.email,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
          if (Recuperar.message.msgId == '0') {
            setColor("success")
            setStatus(Recuperar.message.msgTxt)
            Swal.fire({
              icon: 'success',
              title: 'Correcto',
              text: `${Recuperar.message.msgTxt}`,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/auth/recover-password");
              }
            })
            }
            
          else {
            setColor("danger")
          setStatus(Recuperar.message.msgTxt)
          setSubmitting(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${Recuperar.message.msgTxt}`,
            showConfirmButton: true,
          })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${error}`,
          showConfirmButton: true,
        })
      }
      setLoading(false)
    },
    
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      // ref={form}
      onSubmit={(e)=>{
        formik.handleSubmit(e)
      }
      }
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'FORGOTP.TITLE'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'FORGOTP.SUBTITLE'})}
        </div>
        {/* end::Link */}
      </div>

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${color}`}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div> 
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        </label>
        <input
          type='email'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        {/* <Link to='/auth/recover-password'> */}
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          // onClick={()=>sendEmail}
        >
          {!loading && 'Enviar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Espere...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({id: 'TEXT.CANCEL'})}
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
