import React from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { setLanguage, useLang } from '../../../../_metronic/i18n/Metronici18n'

const LanguageButton = () => {
  
  const intl = useIntl()
    const languages = [
        {
          lang: 'en',
          name: intl.formatMessage({id: 'INGLES'}),
          flag: toAbsoluteUrl('/media/flags/estados_unidos.svg'),
        },
        {
          lang: 'es',
          name: intl.formatMessage({id: 'ESPAÑOL'}),
          flag: toAbsoluteUrl('/media/flags/espania.svg'),
        }
        
      ]
      const lang = useLang()
      const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <div className='me-10'>
      {/* <!--begin::Toggle--> */}
      <button
        className='btn btn-flex btn-link btn-color-gray-700 
        btn-active-color-primary rotate fs-base menu-dropdown
        '
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
        
      >
        <img
          data-kt-element='current-lang-flag'
          className='w-20px h-20px rounded me-3'
          src={currentLanguage?.flag}
          alt=''
        />

        <span data-kt-element='current-lang-name' className='me-1'>
        {currentLanguage?.name}
        </span>

        {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg--> */}
        <span className='svg-icon svg-icon-5 svg-icon-muted rotate-180 m-0'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
              fill='currentColor'
            ></path>
          </svg>
        </span>
        <div
        className='menu-sub menu-sub-dropdown menu-column menu-rounded 
        menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7
        '
        data-kt-menu='true'
        id='kt_auth_lang_menu'
        data-popper-placement='top-start'
        //Para que aparezcan los submenus arriba del menú
        data-kt-menu-offset='top-start'
      >
      {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
        {/* <!--end::Svg Icon-->                 */}
      </button>
      {/* <!--end::Toggle--> */}

      {/* <!--begin::Menu--> */}
      
    </div>
  )
}

export default LanguageButton
