import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {
  findStepsAuthStepper,
  getLocalizaciones,
  getPaises,
  getTiposDocumentos,
} from '../core/_requests'
import {
  DocumentTypeResponseModel,
  LocalizacionesResponseModel,
  PaisesResponseModel,
  StepsResponseModel,
} from '../core/_models'
import {useStepperStore} from '../../../stores/AuthStepperStore'

import StepOne from './steps/StepOne'
import StepTwo from './steps/StepTwo'
import StepThree from './steps/StepThree'
import StepFour from './steps/StepFour'
import StepFive from './steps/StepFive'
import StepSix from './steps/StepSix'
import StepSeven from './steps/StepSeven'
import StepEight from './steps/StepEight'
import StepNine from './steps/StepNine'
import StepTen from './steps/StepTen'
import StepEleven from './steps/StepEleven'
import {Spinner} from 'react-bootstrap'
import StepTwelve from './steps/StepTwelve'
import StepThirteen from './steps/StepThirteen'
import StepFourteen from './steps/StepFourteen'
import StepFifteen from './steps/StepFifteen'
import ProgressBar from './utils/ProgressBar'

const components = {
  Step_1: StepOne,
  Step_2: StepTwo,
  Step_3: StepThree,
  Step_4: StepFour,
  Step_5: StepFive,
  Step_6: StepSix,
  Step_7: StepSeven,
  Step_8: StepEight,
  Step_9: StepNine,
  Step_10: StepTen,
  Step_11: StepEleven,
  Step_12: StepTwelve,
  Step_13: StepThirteen,
  Step_14: StepFourteen,
  Step_15: StepFifteen,
}

const AuthStepper = () => {
  const intl = useIntl()
  const [currentStep, setCurrentStep] = useState(1)
  const [paises, setPaises] = useState<PaisesResponseModel>({} as PaisesResponseModel)
  const [tiposDoc, setTiposDoc] = useState<DocumentTypeResponseModel>(
    {} as DocumentTypeResponseModel
  )
  // const [tiposSer, setTiposSer] = useState<DocumentTypeResponseModel>({} as DocumentTypeResponseModel);
  const [localizaciones, setLocalizaciones] = useState<LocalizacionesResponseModel>(
    {} as LocalizacionesResponseModel
  )
  const [StepsConfig, setStepsConfig] = useState<StepsResponseModel | null>(null)
  const paisStepper = useStepperStore((state) => state.dataStepper.country)
  const setIdOrgSaas = useStepperStore((state) => state.setIdOrgSaas)
  const setOrgSaasName = useStepperStore((state) => state.setOrgSaasName)

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1)
  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1)
  const goAnyStep = (step: number) => setCurrentStep(step)

  const traerPaises = async () => {
    const {data} = await getPaises()
    setPaises(data)
  }

  const traerTipoDocumento = async (pais: string) => {
    const {data} = await getTiposDocumentos(pais, '1')
    setTiposDoc(data)
  }
  // const traerTipoServicio = async (pais: string) => {
  //   const { data } = await getTiposDocumentos(pais,"2");
  //   setTiposSer(data);
  // };

  const traerIdOrgVentaxDominio = async () => {
    const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;
    const {data} = await findStepsAuthStepper(
      dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual
    )
    if (data.message.msgId == '0') {
      setStepsConfig(data)
      setIdOrgSaas(data.registro.idOrgSaas)
      setOrgSaasName(data.registro.nombreOrgSaas)
    } else {
      console.log(data.message.msgTxt)
    }
  }

  const traerLocalizacionesNivel1 = async () => {
    const {data} = await getLocalizaciones(paisStepper ? paisStepper : 'PE', 1)
    setLocalizaciones(data)
  }

  useEffect(() => {
    traerPaises()
    traerIdOrgVentaxDominio()
  }, [])

  useEffect(() => {
    if (currentStep === 1 || paisStepper !== '') {
      traerLocalizacionesNivel1()
      traerTipoDocumento(paisStepper ? paisStepper : 'PE')
      // traerTipoServicio(paisStepper ? paisStepper : 'PE')
    }
  }, [currentStep, paisStepper])

  if (!StepsConfig)
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' className='text-primary' />
      </div>
    )

  const allSteps = StepsConfig.registro.etapasVista
    .map((etapa) =>
      etapa.vistas.map((vista) => ({
        ...vista,
        etapaId: etapa.IdEtapa, // Agregamos el identificador de la etapa a cada vista
      }))
    )
    .flat() // Aplanamos el array manteniendo el orden de las etapas
    .sort((a, b) => a.etapaId - b.etapaId || a.Secuencia - b.Secuencia)
  const lastShowedStep = allSteps[allSteps.length - 1].NombreTecnico
  console.log(lastShowedStep)
  const CurrentStepComponent =
    components[allSteps[currentStep - 1]?.NombreTecnico as keyof typeof components]

  return (
    <div>
      {currentStep === 1 && (
        <>
          <h1 className='text-dark fw-bolder mb-3 text-center'>
            {intl.formatMessage({id: 'AUTH_STEPPER_TITLE'})}
          </h1>
          <h6 className='text-center mb-10'>
            {intl.formatMessage({id: 'AUTH.GENERAL.YES_ACCOUNT'})},{' '}
            <Link to='/auth/login'>{intl.formatMessage({id: 'LOGIN.TITLE'})}</Link>
          </h6>
        </>
      )}
      <div>
        {CurrentStepComponent && (
          <CurrentStepComponent
            nextStep={nextStep}
            prevStep={prevStep}
            goAnyStep={goAnyStep}
            paises={paises}
            tiposDoc={tiposDoc}
            localizacionesNivel1={localizaciones}
            lastShowedStep={lastShowedStep}
          />
        )}
      </div>
      <ProgressBar currentStep={currentStep} allSteps={allSteps} />
    </div>
  )
}

export default AuthStepper
