import {useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import {sendVerificationCodeSms} from '../../core/_requests'
import Swal from 'sweetalert2'
const StepFive = ({prevStep, nextStep}: {prevStep: () => void; nextStep: () => void}) => {
  const intl = useIntl()
  const {setTelephone} = useStepperStore((state) => state)
  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const backStep = () => {
    prevStep()
  }

  const onSubmit = async (data: any) => {
    setTelephone(data.telefono)
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await sendVerificationCodeSms(emailStepper, data.telefono,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
      if (response.data.message.msgId == '0') {
        Swal.fire({
          icon: 'success',
          title: 'Correcto',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
        nextStep()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label fs-5' htmlFor='telefono'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP5.LABEL'})}
      </label>
      <input
        id='telefono'
        className='form-control form-control-lg'
        type='text'
        {...register('telefono', {
          required: {value: true, message: intl.formatMessage({id: 'LOGIN.VALIDATION.TELREQUIRED'})},
          maxLength: {value: 10, message: intl.formatMessage({id: 'LOGIN.VALIDATION.TELMAX'})},
          minLength: {value: 9, message: intl.formatMessage({id: 'LOGIN.VALIDATION.TELMAX'})},
          pattern: {value: /^[0-9]+$/, message: intl.formatMessage({id: 'LOGIN.VALIDATION.TELFORMAT'})},
        })}
      />
      {errors.telefono && <p className='text-danger'>{errors.telefono.message?.toString()}</p>}
      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </form>
  )
}

export default StepFive
