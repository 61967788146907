import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {RegistroModAplicativo, useAuth} from '../../modules/auth'

const UserModule: FC = () => {
  const [data, setData] = useState<RegistroModAplicativo[]>([])
  const {currentUser} = useAuth()

  useEffect(() => {
    if (currentUser?.registro.cat_rol) {
      const modulos: RegistroModAplicativo[] = currentUser.registro.modulospermitidos

      setData(modulos)
    }
  }, [currentUser])

  return (
    <div className='m-10 card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            Bienvenido (a){' '}
            {currentUser?.registro.cat_rol ? currentUser?.registro.cat_rol.Descripcion : '-'} :{' '}
            {currentUser?.registro.Nombres}
          </h3>
        </div>
      </div>
      <div className='card-body p-9 mx-10'>
        {data.length > 0 ? (
          <>
            {data.map((d) => (
              <div
                key={d.IdModulo}
                className='d-flex align-items-center bg-light-secondary rounded p-5 mb-3'
              >
                <div className='flex-grow-1 me-2'>
                  <span className='fw-bold text-gray-800 fs-3'>{d.Descripcion}</span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className='text-center text-muted'>
              <span className='fw-bold fs-3'>No hay modulos permitidas para este usuario.</span>
            </div>
          </>
        )}
      </div>
      <div className='mb-5 me-5 d-flex justify-content-end'>
        <Link to='/dashboard' className='btn btn-primary'>
          Continuar
        </Link>
      </div>
    </div>
  )
}

export {UserModule}
