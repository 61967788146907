import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {RegistroFuncionalidad, useAuth} from '../../modules/auth'
import {PageTitle} from '../../../_metronic/layout/core'

const AllowedFunctionalitiesContent: FC = () => {
  const location = useLocation();
  const [data, setData] = useState<RegistroFuncionalidad[]>([]);
  const {currentUser} = useAuth();

  const [expanded, setExpanded] = useState<{[key: number]: boolean}>({});

  useEffect(() => {
    if (currentUser?.registro.cat_rol) {
      const funcionalidades: RegistroFuncionalidad[] = currentUser.registro.cat_rol.funcionalidad;
      console.log(funcionalidades.length);
      console.log(funcionalidades);
      setData(funcionalidades);
    }
  }, [currentUser]);

  // Controla el estado de expansión para cada funcionalidad
  const toggleExpand = (id: number) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  // Función recursiva para renderizar el árbol de funcionalidades
  const renderTree = (nodes: RegistroFuncionalidad[], level: number = 1) => {
    return nodes.map((node) => (
      <div key={node.IdFuncionalidad} style={{marginLeft: `${20 * (level - 1)}px`}}>
        <div
          className={`d-flex align-items-center ${
            level === 1 ? 'bg-light-secondary' : 'bg-light'
          } rounded p-3 mb-2`}
        >
          <div className='flex-grow-1 me-2'>
            <span className={`fw-bold text-gray-800 fs-${3 + level}`}>{node.Descripcion}</span>
          </div>
          {/* Botón para expandir/colapsar si tiene subniveles */}
          {node.funcionalidadhijo && node.funcionalidadhijo.length > 0 && (
            <button
              onClick={() => toggleExpand(node.IdFuncionalidad)}
              className='btn btn-sm btn-toggle ms-2'
            >
              {expanded[node.IdFuncionalidad] ? '▼' : '▶'}
            </button>
          )}
        </div>
        {/* Renderiza los subniveles solo si está expandido */}
        {expanded[node.IdFuncionalidad] && node.funcionalidadhijo && renderTree(node.funcionalidadhijo, level + 1)}

      </div>
    ));
  };

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            Bienvenido (a){' '}
            {currentUser?.registro.cat_rol ? currentUser?.registro.cat_rol.Descripcion : '-'} :{' '}
            {currentUser?.registro.Nombres}
          </h3>
        </div>
      </div>
      <div className='card-body p-9 mx-10'>
        {data && data.length > 0 ? (
          renderTree(data)
        ) : (
          <div className='text-center text-muted'>
            <span className='fw-bold fs-3'>No hay funcionalidades permitidas para este rol.</span>
          </div>
        )}
      </div>
      <div className='mb-5 me-5 d-flex justify-content-end'>
        <Link to='/dashboard' className='btn btn-primary'>
          Continuar
        </Link>
      </div>
    </div>
  );
};

const AllowedFunctionalities: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Menú Principal</PageTitle>
      <AllowedFunctionalitiesContent />
    </>
  )
}

export {AllowedFunctionalities}
