import {Controller, useForm} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {addIdentity} from '../../core/_requests'
import {useStepperStore} from '../../../../stores/AuthStepperStore'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap'
const StepEight = ({
  prevStep,
  nextStep,
  lastShowedStep,
}: {
  prevStep: () => void
  nextStep: () => void
  lastShowedStep: string
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const emailStepper = useStepperStore((state) => state.dataStepper.email)
  const typeAccountStepper = useStepperStore((state) => state.dataStepper.accountType)
  const idOrgSaasStepper = useStepperStore((state) => state.dataStepper.idOrgSaas)
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm({
    defaultValues: {
      rSocial: '',
      name: '',
      apPaterno: '',
      apMaterno: '',
      birthdate: '',
      gender: 'M',
    },
  })

  const onSubmit = async (data: any) => {
    try {
      const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
      const response = await addIdentity(
        data.rSocial,
        emailStepper,
        data.name,
        data.apPaterno,
        data.apMaterno,
        data.birthdate,
        lastShowedStep == 'Step_8' ? true : false,
        dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual,
        data.gender
      )
      if (response.data.message.msgId == '0') {
        if (lastShowedStep == 'Step_8') {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
            confirmButtonText: 'Iniciar Sesion',
          }).then(() => {
            navigate('/auth/login')
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Correcto',
            text: `${response.data.message.msgTxt}`,
            showConfirmButton: true,
          })
          nextStep()
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.data.message.msgTxt}`,
          showConfirmButton: true,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salio mal',
        showConfirmButton: true,
      })
    }
  }

  return (
    <form className='d-flex flex-column gap-2' onSubmit={handleSubmit(onSubmit)}>
      <label className='form-label fs-5 text-center w-100 m-0'>
        {intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL'})}
      </label>
      <br />
       {typeAccountStepper === 2 && (
        <>
          {/* Razon Social */}
          <label className='form-label fs-6' htmlFor='rSocial'>
          {intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL1'})}
          </label>
          <input
            id='rSocial'
            className='form-control form-control-lg'
            type='text'
            {...register('rSocial', {
              required: {
                value: true,
                message: intl.formatMessage({id: 'LOGIN.VALIDATION.FIRSTNAMEREQUIRED'}),
              },
            })}
          />
          {errors.rSocial && <p className='text-danger'>{errors.rSocial.message?.toString()}</p>}
        </>
      )}
      {typeAccountStepper === 2 && <p>{intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL2'})}</p>}
      {/* Name */}
      <label className='form-label fs-6' htmlFor='name'>
        {intl.formatMessage({id: 'REGISTER.FIRSTNAME'})} :
      </label>
      <input
        id='name'
        className='form-control form-control-lg'
        type='text'
        {...register('name', {
          required: {
            value: true,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.FIRSTNAMEREQUIRED'}),
          },
          pattern: {
            value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ONLY_LETTERS'}),
          },
        })}
      />
      {errors.name && <p className='text-danger'>{errors.name.message?.toString()}</p>}

      {/* Last Name1 */}
      <label className='form-label fs-6' htmlFor='apPaterno'>
        {intl.formatMessage({id: 'REGISTER.LASTNAME1'})} :
      </label>
      <input
        id='apPaterno'
        className='form-control form-control-lg'
        type='text'
        {...register('apPaterno', {
          required: {
            value: true,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.LASTNAME1REQUIRED'}),
          },
          pattern: {
            value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ONLY_LETTERS'}),
          },
        })}
      />
      {errors.apPaterno && <p className='text-danger'>{errors.apPaterno.message?.toString()}</p>}

      {/* Last Name2 */}
      <label className='form-label fs-6' htmlFor='apMaterno'>
        {intl.formatMessage({id: 'REGISTER.LASTNAME2'})} :
      </label>
      <input
        id='apMaterno'
        className='form-control form-control-lg'
        type='text'
        {...register('apMaterno', {
          required: {
            value: true,
            message: intl.formatMessage({id: 'LOGIN.VALIDATION.LASTNAME2REQUIRED'}),
          },
          pattern: {
            value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
            message: intl.formatMessage({id: 'VALIDATION.ONLY_LETTERS'}),
          },
        })}
      />
      {errors.apMaterno && <p className='text-danger'>{errors.apMaterno.message?.toString()}</p>}

      {/* birthdate */}
      <label className='form-label fs-6' htmlFor='birthdate'>
        {intl.formatMessage({id: 'REGISTER.BIRTHDATE'})} :
      </label>
      <input
        id='birthdate'
        className='form-control form-control-lg'
        type='date'
        {...register('birthdate', {
          required: {value: true, message: intl.formatMessage({id: 'VALIDATION.REQUIRED'})},
          validate: {
            isAdult: (value) => {
              // Crear una fecha exacta sin problemas de zona horaria
              const birthDate = new Date(value + 'T00:00:00') // Asegurarse de que no hay ajuste de zona horaria
              const today = new Date()
              let age = today.getFullYear() - birthDate.getFullYear()

              // Verificar si el cumpleaños aún no ha ocurrido este año
              const monthDiff = today.getMonth() - birthDate.getMonth()
              if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age-- // Si el mes actual es anterior o es el mismo mes pero el día no ha llegado, resta 1 año
              }

              // Comprobar si la edad es mayor o igual a 18 años
              return age >= 18 || intl.formatMessage({id: 'VALIDATION.AGE.MIN'})
            },
          },
        })}
      />
      {errors.birthdate && <p className='text-danger'>{errors.birthdate.message?.toString()}</p>}

      {/* gender */}
      <label className='form-label fs-6' htmlFor='gender'>
      {intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL3'})}
      </label>
      <div className='d-flex justify-content-start align-items-center mt-3'>
        <Controller
          name='gender'
          control={control}
          rules={{required: 'Selecciona un género'}}
          render={({field: {onChange, value}}) => (
            <ToggleButtonGroup
              type='radio'
              name='gender'
              className='border border-2 rounded'
              value={value}
              onChange={onChange}
            >
              <ToggleButton variant='outline-primary' value='M' id='masculino'>
                <i className='bi bi-gender-male fs-2 text-dark'></i>{intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL4'})}
              </ToggleButton>
              <ToggleButton variant='outline-primary' value='F' id='femenino'>
                <i className='bi bi-gender-female fs-2 text-dark'></i>{intl.formatMessage({id: 'AUTH.REGISTER.STEP8.LABEL5'})}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </div>

      {errors.gender && <p className='text-danger'>{errors.gender.message?.toString()}</p>}

      <div className='d-flex justify-content-center'>
        {/* <button type='button' className='btn btn-secondary mt-4' onClick={backStep}>
          {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
        </button> */}
        <button className='btn btn-primary mt-4'>
          {intl.formatMessage({id: 'AUTH_STEPPER_BTNNEXT'})}
        </button>
      </div>
    </form>
  )
}

export default StepEight
