import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const BackGeneral = () => {
  const navigate = useNavigate()

  const [theme, setTheme] = useState('')

  const goBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem('kt_theme_mode_value')
    if (storedTheme) {
      setTheme(storedTheme)
    }
  }, [])

  const backgroundColor = theme === 'light' ? '#F5F8FA' : '#1E1E2D'

  return (
    <div
      className='container-fluid  px-0 d-block' 
      style={{position: 'sticky', top: 0, zIndex: 99, backgroundColor}}
    >
      <div className='my-2 mx-3'>
        <div className='card-body d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center justify-content-between'>
            <button className='btn btn-primary' onClick={goBack}>
              <i className='fas fa-arrow-left'></i>
            </button>
            <p className='fs-1 ms-3 fw-bold m-0'>RENT A CAR</p>
          </div>
          <div>
            <p className='fs-1 fw-bolder text-dark m-0'>BACKOFFICE</p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='fs-4 fw-bold m-0'>HELP</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default BackGeneral
