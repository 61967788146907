import React, {useState, useContext, createContext, FC, PropsWithChildren, useEffect} from 'react'

interface UserContextCar {
  idCar: number
  setIdCar: (idCar: number) => void
}

interface UserContextSociedad {
  idSociedad: string
  setIdSociedad: (idSociedad: string) => void
}

interface UserContextOrgVentas {
  idOrgVentas: string
  setIdOrgVentas: (idOrgVentas: string) => void
}

interface UserContextOfVentas {
  idOfVentas: string
  setIdOfVentas: (idOfVentas: string) => void
}

interface UserContextEsqEstatus {
  idEsqEstatus: string
  setIdEsqEstatus: (idEsqEstatus: string) => void
}

interface UserContextSector {
  idSector: string
  setIdSector: (idSector: string) => void
}

interface UserContextFAQ {
  idFAQ: number
  setIdFAQ: (idFAQ: number) => void
}

interface UserContextGrpConsentimiento {
  idGrpConsentimiento: number
  setIdGrpConsentimiento: (idGrpConsentimiento: number) => void
}

interface UserContextClienteEmpresarial {
  idClienteEmpresarial: number
  setIdClienteEmpresarial: (idClienteEmpresarial: number) => void
}
interface UserContextClientePersona {
  idClientePersona: number
  setIdClientePersona: (idClientePersona: number) => void
}
interface UserContextContacto {
  idContacto: number
  setIdContacto: (idContacto: number) => void
}

interface UserContextOrgSaas {
  idOrgSaas: number
  setIdOrgSaas: (idOrgSaas: number) => void
}

const userContextCar = createContext<UserContextCar>({idCar: 0, setIdCar: () => {}})

const userContextSociedad = createContext<UserContextSociedad>({
  idSociedad: '',
  setIdSociedad: () => {},
})

const userContextOrgVentas = createContext<UserContextOrgVentas>({
  idOrgVentas: '',
  setIdOrgVentas: () => {},
})

const userContextOfVentas = createContext<UserContextOfVentas>({
  idOfVentas: '',
  setIdOfVentas: () => {},
})

const userContextEsqEstatus = createContext<UserContextEsqEstatus>({
  idEsqEstatus: '',
  setIdEsqEstatus: () => {},
})

const userContextSector = createContext<UserContextSector>({
  idSector: '',
  setIdSector: () => {},
})

const userContextFAQ = createContext<UserContextFAQ>({idFAQ: 0, setIdFAQ: () => {}})

const userContextGrpConsentimiento = createContext<UserContextGrpConsentimiento>({
  idGrpConsentimiento: 0,
  setIdGrpConsentimiento: () => {},
})

const userContextClienteEmpresarial = createContext<UserContextClienteEmpresarial>({
  idClienteEmpresarial: 0,
  setIdClienteEmpresarial: () => {},
})
const userContextClientePersona = createContext<UserContextClientePersona>({
  idClientePersona: 0,
  setIdClientePersona: () => {},
})
const userContextContacto = createContext<UserContextContacto>({
  idContacto: 0,
  setIdContacto: () => {},
})

const userContextOrgSaas = createContext<UserContextOrgSaas>({
  idOrgSaas: 0,
  setIdOrgSaas: () => {},
})

export function useContextCar() {
  return useContext(userContextCar)
}

export function useContextSociedad() {
  return useContext(userContextSociedad)
}

export function useContextOrgVentas() {
  return useContext(userContextOrgVentas)
}

export function useContextOfVentas() {
  return useContext(userContextOfVentas)
}

export function useContextEsqEstatus() {
  return useContext(userContextEsqEstatus)
}

export function useContextSector() {
  return useContext(userContextSector)
}

export function useContextFAQ() {
  return useContext(userContextFAQ)
}

export function useContextGrpConsentimiento() {
  return useContext(userContextGrpConsentimiento)
}

export function useContextClienteEmpresarial() {
  return useContext(userContextClienteEmpresarial)
}
export function useContextClientePersona() {
  return useContext(userContextClientePersona)
}
export function useContextContacto() {
  return useContext(userContextContacto)
}

export function useContextOrgSaas() {
  return useContext(userContextOrgSaas)
}

export const DataContext: FC<PropsWithChildren<{}>> = (props) => {
  const [idCar, setIdCar] = useState<number>(0)

  const [idSociedad, setIdSociedad] = useState<string>('')
  const [idOrgVentas, setIdOrgVentas] = useState<string>('')
  const [idOfVentas, setIdOfVentas] = useState<string>('')
  const [idEsqEstatus, setIdEsqEstatus] = useState<string>('')
  const [idSector, setIdSector] = useState<string>('')
  const [idFAQ, setIdFAQ] = useState<number>(0)
  const [idGrpConsentimiento, setIdGrpConsentimiento] = useState<number>(0)
  const [idClienteEmpresarial, setIdClienteEmpresarial] = useState<number>(0)
  const [idClientePersona, setIdClientePersona] = useState<number>(0)
  const [idContacto, setIdContacto] = useState<number>(0)
  const [idOrgSaas, setIdOrgSaas] = useState<number>(0)

  useEffect(() => {
    const storedDataCar = localStorage.getItem('idCar')
    if (storedDataCar) {
      const parsedData = parseInt(storedDataCar)
      setIdCar(parsedData)
    }

    const storedDataSociedad = localStorage.getItem('idSociedad')
    if (storedDataSociedad) {
      setIdSociedad(storedDataSociedad)
    }

    const storedDataOrgVentas = localStorage.getItem('idOrgVentas')
    if (storedDataOrgVentas) {
      setIdOrgVentas(storedDataOrgVentas)
    }

    const storedDataOfVentas = localStorage.getItem('idOfVentas')
    if (storedDataOfVentas) {
      setIdOfVentas(storedDataOfVentas)
    }

    const storedDataEsqEstatus = localStorage.getItem('idEsqEstatus')
    if (storedDataEsqEstatus) {
      setIdEsqEstatus(storedDataEsqEstatus)
    }

    // const storedDataSector = localStorage.getItem('idSector')
    // if (storedDataSector) {
    //   const parsedData = storedDataSector
    //   console.log(storedDataSector);
    //   setIdSector(parsedData)
    // }

    const storedDataSector = localStorage.getItem('idSector')
    if (storedDataSector) {
      setIdSector(storedDataSector)
    }

    const storedDataFAQ = localStorage.getItem('idFAQ')
    if (storedDataFAQ) {
      const parsedData = parseInt(storedDataFAQ)
      setIdFAQ(parsedData)
    }

    const storedDataGrpConsentimiento = localStorage.getItem('idGrpConsentimiento')
    if (storedDataGrpConsentimiento) {
      const parsedData = parseInt(storedDataGrpConsentimiento)
      setIdGrpConsentimiento(parsedData)
    }

    const storedDataClienteEmpresarial = localStorage.getItem('idClienteEmpresarial')
    if (storedDataClienteEmpresarial) {
      const parsedData = parseInt(storedDataClienteEmpresarial)
      setIdClienteEmpresarial(parsedData)
    }
    const storedDataClientePersona = localStorage.getItem('idClientePersona')
    if (storedDataClientePersona) {
      const parsedData = parseInt(storedDataClientePersona)
      setIdClientePersona(parsedData)
    }
    const storedDataContacto = localStorage.getItem('idContacto')
    if (storedDataContacto) {
      const parsedData = parseInt(storedDataContacto)
      setIdContacto(parsedData)
    }

    const storedDataOrgSaas = localStorage.getItem('idOrgSaas')
    if (storedDataOrgSaas) {
      const parsedData = parseInt(storedDataOrgSaas)
      setIdOrgSaas(parsedData)
    }
  }, [])

  return (
    <userContextCar.Provider value={{idCar, setIdCar}}>
      <userContextSociedad.Provider value={{idSociedad, setIdSociedad}}>
        <userContextOrgVentas.Provider value={{idOrgVentas, setIdOrgVentas}}>
          <userContextOfVentas.Provider value={{idOfVentas, setIdOfVentas}}>
            <userContextEsqEstatus.Provider value={{idEsqEstatus, setIdEsqEstatus}}>
              <userContextSector.Provider value={{idSector, setIdSector}}>
                <userContextFAQ.Provider value={{idFAQ, setIdFAQ}}>
                  <userContextGrpConsentimiento.Provider
                    value={{idGrpConsentimiento, setIdGrpConsentimiento}}
                  >
                    <userContextClienteEmpresarial.Provider
                      value={{idClienteEmpresarial, setIdClienteEmpresarial}}
                    >
                      <userContextClientePersona.Provider
                        value={{idClientePersona, setIdClientePersona}}
                      >
                        <userContextContacto.Provider value={{idContacto, setIdContacto}}>
                          <userContextOrgSaas.Provider value={{idOrgSaas, setIdOrgSaas}}>
                            {props.children}
                          </userContextOrgSaas.Provider>
                        </userContextContacto.Provider>
                      </userContextClientePersona.Provider>
                    </userContextClienteEmpresarial.Provider>
                  </userContextGrpConsentimiento.Provider>
                </userContextFAQ.Provider>
              </userContextSector.Provider>
            </userContextEsqEstatus.Provider>
          </userContextOfVentas.Provider>
        </userContextOrgVentas.Provider>
      </userContextSociedad.Provider>
    </userContextCar.Provider>
  )
}
